import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { DxButton, DxContainer, DxSpacer, DxText } from '@dvag/design-system-react';

import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { navigateNext, navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import { useParams, useLocation } from 'react-router-dom';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import { usePersonList } from 'hooks/usePersonList';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { dashboardUtil } from 'service/model/dashboardUtil';
import { useNotification } from 'utils/notification/NotificationContext';
import { useContinuation } from 'utils/useContinuation';
import { WishGoalCardsMap } from './WishGoalCardsMap';
import { Footer } from './component/Footer';
import { Loading } from './component/Loading/Loading';
import { PersonDropdown } from './component/PersonDropdown/PersonDropdown';
import { TopButton } from './component/TopThreeButton/TopButton';
import { CopyModal } from './modal/CopyModal/CopyModal';
import { useCopyWishGoalList } from './service/useCopyWishGoalList';
import { useGetPersonWishGoalList } from './service/useGetPersonWishGoalList';
import { getAppInsights } from '../../service/getAppInsights';

import { TopDisplayType } from './type';
import './style.css';

const translation = {
  pageTitle: i18next.t('wishesAndGoals.headerTitle'),
  copyButtonText: i18next.t('wishesAndGoals.copyModal.copyButton'),
  showTopText: i18next.t('wishesAndGoals.showTop'),
  hideTopText: i18next.t('wishesAndGoals.hideTop'),
  toastErrorMessage: i18next.t('general.toasters.loading.generic.title'),
};

interface wishesAndGoalsProps {
  hideFinancialGrade?: boolean;
}

const updatePersonIdParam = (
  personId: string,
  hideFinancialGrade: boolean,
  householdId: string,
) => {
  const navigationRoute = hideFinancialGrade
    ? route.wishesAndGoalsHideFinancialGradeWithSelectedPerson
    : route.wishesAndGoalsWithSelectedPerson;
  navigateToMicroApp(
    'person',
    buildPath(navigationRoute, {
      householdId,
      personId,
    }),
  );
};

export const WishAndGoal = ({ hideFinancialGrade = false }: wishesAndGoalsProps) => {
  const { showError } = useNotification();
  const location = useLocation();
  const isSalesforce = location.pathname.includes('salesforce');
  const { householdId, personId: paramPersonId } = useParams();
  const personList = usePersonList();
  const mainPerson = personList.data?.[0] || null;
  const mainPersonId = mainPerson?.id ?? '';

  const [copyModalVisibility, setCopyModalVisibility] = useState(false);
  const [selectedPersonDropdown, setSelectedPersonDropdown] = useState('');
  const selectedPersonId = paramPersonId || selectedPersonDropdown || mainPersonId;
  const [topDisplayType, setTopDisplayType] = useState<TopDisplayType>('hide');
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: initialWgList,
    isLoading: isInitialWgListLoading,
    isFetching: isInitialWgListFetching,
  } = useGetPersonWishGoalList(selectedPersonId);

  const [wgList, setWgList] = useState(initialWgList);
  const copyWgList = useCopyWishGoalList();
  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const hasAtLeastOneWgSaved = wgList.some((wg) => wg.id);

  const closeCopyModal = () => setCopyModalVisibility(false);

  const openCopyModal = () => setCopyModalVisibility(true);

  useEffect(() => {
    if (initialWgList.length) {
      setWgList(initialWgList);
    }
  }, [initialWgList]);

  useEffect(() => {
    onCanContinueChange(!isDirty && !copyWgList.isPending);
  }, [onCanContinueChange, isDirty, copyWgList.isPending]);

  const { appInsights } = getAppInsights();

  const doCopy = async (selectedPersonIdList: string[]) => {
    if (!selectedPersonId) {
      showError({ title: translation.toastErrorMessage });
      return;
    }
    try {
      await copyWgList.mutateAsync({
        personId: selectedPersonId,
        personIdList: selectedPersonIdList,
      });
    } catch (e) {
      appInsights.trackException({
        exception: e as Error,
        severityLevel: 3,
      });
      showError({ title: translation.toastErrorMessage });
    }
  };

  const renderCopyButton = () => {
    const areAtLeastTwoPersons = personList.data?.length > 1;
    const isListNotEmpty = wgList.length > 0;
    if (isListNotEmpty && areAtLeastTwoPersons) {
      return (
        <DxButton
          id="wg-copy-button"
          type="secondary-m"
          data-testid="copy-button"
          onClick={openCopyModal}
          label={translation.copyButtonText}
          stretch
        />
      );
    }

    return null;
  };

  const hasBottom = topDisplayType === 'hide' ? 'has-bottom' : '';
  const isCustomerSalesforce = isSalesforce && paramPersonId;

  return (
    <DxContainer color="transparent" data-testid="wg-page-container">
      <DxContainer color="transparent" className="main-item-container">
        <DxContainer
          className="wishes-and-goals-title-container container-wrapper"
          color="transparent"
        >
          <DxText className="title" type="h3">
            {translation.pageTitle}
          </DxText>

          {isCustomerSalesforce ? (
            <DxContainer color="transparent" className="show-top-button-container">
              <TopButton
                disabled={!wgList.some((wg) => wg.isDesired)}
                selectedPersonId={selectedPersonId}
                onChange={setTopDisplayType}
              />
            </DxContainer>
          ) : (
            <PersonDropdown
              className="person-dropdown"
              selectedId={selectedPersonId}
              onChange={(newPersonId) => {
                getContinuationHandler(() => {
                  if (isSalesforce) {
                    setSelectedPersonDropdown(newPersonId);
                    return;
                  }
                  updatePersonIdParam(newPersonId, hideFinancialGrade, householdId as string);
                })?.();
              }}
            />
          )}
        </DxContainer>
      </DxContainer>
      <DxContainer color="transparent" className="main-item-container">
        <DxContainer color="transparent" className="container-wrapper">
          <DxContainer color="transparent" className="wishes-and-goals-action-container">
            {!isSalesforce && (
              <DxContainer color="transparent" className="copy-button-container">
                {renderCopyButton()}
              </DxContainer>
            )}
            {isCustomerSalesforce ? null : (
              <DxContainer color="transparent" className="show-top-button-container">
                <TopButton
                  disabled={!wgList.some((wg) => wg.isDesired)}
                  selectedPersonId={selectedPersonId}
                  onChange={setTopDisplayType}
                />
              </DxContainer>
            )}
          </DxContainer>
          <DxContainer color="transparent" className={`list-wrapper ${hasBottom}`}>
            {!selectedPersonId ||
            !wgList.length ||
            isInitialWgListLoading ||
            isInitialWgListFetching ? (
              <Loading props={topDisplayType} />
            ) : (
              <WishGoalCardsMap
                personId={selectedPersonId}
                initialWgList={initialWgList}
                topDisplayType={topDisplayType}
                setIsDirty={setIsDirty}
                setIsSubmitting={setIsSubmitting}
                onWgListChange={setWgList}
                onContinuationRequest={onContinuationRequest}
              />
            )}
          </DxContainer>
          {!isSalesforce ? (
            <Footer
              handleBack={getContinuationHandler(() => {
                navigatePrevious(
                  buildPath(`/${appNames.advisor}${sharedAppRoutes.advisor.householdDashboard}`, {
                    householdId: householdId as string,
                  }),
                );
              })}
              handleContinue={getContinuationHandler(() => {
                navigateNext(
                  buildPath(`/${appNames.vpd}${sharedAppRoutes.vpd.analysis.ffg.start}`, {
                    householdId: householdId as string,
                  }),
                );
              })}
              handleGoToFinancialNote={getContinuationHandler(() => {
                handleNavigateToPath(
                  sharedAppRoutes.person.financeNote,
                  householdId,
                  dashboardUtil.ACCOUNTING,
                );
              })}
              hideFinancialGrade={hideFinancialGrade}
            />
          ) : (
            <DxSpacer size="16v" />
          )}
        </DxContainer>
      </DxContainer>
      <CopyModal
        visible={copyModalVisibility}
        isSubmitDisabled={isDirty || !hasAtLeastOneWgSaved}
        onClosed={closeCopyModal}
        personList={personList.data}
        personId={selectedPersonId}
        onCancel={closeCopyModal}
        onSubmit={(selectedPersonIdList) => {
          closeCopyModal();
          doCopy(selectedPersonIdList);
        }}
      />
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting && isDirty}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </DxContainer>
  );
};
