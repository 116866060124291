import { FC, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { syncOrchestratorUrl } from '@dvag/dfs-orchestrator-client/navigation';
import { Error404 } from '@dvag/dfs-ui-blocks/components';

import { route } from 'config/route';
import { ManageHousehold } from 'screen/ManageHousehold/ManageHousehold';
import { SavingType } from 'screen/SavingType/SavingType';
import { WishAndGoal } from 'screen/WishAndGoal/WishAndGoal';
import { BankSelection } from './screen/Flc/BankSelection/BankSelection';
import { BankAnalysis } from './screen/Flc/BankAnalysis/BankAnalysis';
import { RouterErrorBoundary } from './RouterErrorBoundary';
import { PersonalDataForm } from './screen/PersonalDataForm';

const {
  person,
  manageHousehold,
  createHousehold,
  wishesAndGoals,
  wishesAndGoalsWithSelectedPerson,
  wishesAndGoalsSalesforce,
  wishesAndGoalsSalesforceWithSelectedPerson,
  wishesAndGoalsHideFinancialGrade,
  wishesAndGoalsHideFinancialGradeWithSelectedPerson,
  savingType,
  bankSelection,
  bankAnalysis,
  personWithActiveForm,
} = route;

const App = () => {
  const isInitialMount = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (location.key) {
      syncOrchestratorUrl(`${location.pathname}`);
    }
  }, [location]);

  return (
    <Suspense fallback={null}>
      <RouterErrorBoundary>
        <Routes>
          <Route path={person} element={<PersonalDataForm />} />
          <Route path={personWithActiveForm} element={<PersonalDataForm />} />
          <Route path={savingType} element={<SavingType />} />
          <Route path={createHousehold} element={<ManageHousehold />} />
          <Route path={manageHousehold} element={<ManageHousehold />} />
          <Route path={wishesAndGoals} element={<WishAndGoal />} />
          <Route path={wishesAndGoalsWithSelectedPerson} element={<WishAndGoal />} />
          <Route path={wishesAndGoalsSalesforce} element={<WishAndGoal />} />
          <Route path={wishesAndGoalsSalesforceWithSelectedPerson} element={<WishAndGoal />} />
          <Route
            path={wishesAndGoalsHideFinancialGrade}
            element={<WishAndGoal hideFinancialGrade />}
          />
          <Route
            path={wishesAndGoalsHideFinancialGradeWithSelectedPerson}
            element={<WishAndGoal hideFinancialGrade />}
          />
          <Route path={bankSelection} element={<BankSelection />} />
          <Route path={bankAnalysis} element={<BankAnalysis />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </RouterErrorBoundary>
    </Suspense>
  );
};

export const Router: FC = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
