import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { DxButton } from '@dvag/design-system-react';
import { checkIcon } from 'utils/util';
import { TopDisplayType } from '../../type';
import './style.css';

interface TopButtonProps {
  disabled: boolean;
  selectedPersonId: string;
  onChange?: (displayType: TopDisplayType) => void;
}

const idListKey = 'showTop3';

const getIdList = (): string[] => JSON.parse(localStorage.getItem(idListKey) || '[]');

const saveIdList = (idList: string[]) => localStorage.setItem(idListKey, JSON.stringify(idList));

export const TopButton = ({ disabled, selectedPersonId, onChange }: TopButtonProps) => {
  const [idSet, setIdSet] = useState(new Set(getIdList()));
  const initialIsShowState = idSet.has(selectedPersonId);
  const [isShowState, setIsShowState] = useState(initialIsShowState);

  useEffect(() => {
    const newIsShowState = idSet.has(selectedPersonId);
    setIsShowState(newIsShowState);
    onChange?.(newIsShowState ? 'show' : 'hide');
  }, [selectedPersonId, idSet, onChange]);

  useEffect(() => {
    saveIdList([...idSet]);
  }, [idSet]);

  const onClick = () => {
    const newIsShowState = !isShowState;
    setIsShowState(newIsShowState);
    if (newIsShowState) {
      idSet.add(selectedPersonId);
    } else {
      idSet.delete(selectedPersonId);
    }
    setIdSet(new Set(idSet));
    onChange?.(newIsShowState ? 'show' : 'hide');
  };

  const icon = !isShowState ? 'auge' : 'auge-durchgestrichen';
  const label = !isShowState
    ? i18next.t('wishesAndGoals.showTop')
    : i18next.t('wishesAndGoals.hideTop');

  return (
    <DxButton
      id="wg-show-top-button"
      className="top-button"
      data-testid="show-top"
      icon={checkIcon(icon)}
      iconposition="right"
      disabled={disabled}
      label={label}
      stretch
      type="primary-m"
      onClick={onClick}
    />
  );
};
